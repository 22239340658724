const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://norito-blog.com',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    // TODO: ロゴの変更
    logo: '',

    // logoImage: "./images/noritake-blog-logo2.png",
    logoImage: "",

    logoLink: 'https://norito-blog.com',
    title: "<a style='font-weight: bold' href='https://norito-blog.com'>NORITO-BLOG</a>",

    // TODO: github URL
    // githubUrl: 'https://github.com/hasura/gatsby-gitbook-boilerplate',
    githubUrl: '',

    helpUrl: '',
    tweetText: '',

    // githubのリンクはこれに設定するみたい
    social: ``,

    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
    ],
    collapsedNav: [
      '/php',
      '/php/laravel',
      '/vscode',
      '/aws',
      '/aws/datapipeline',
      '/postgres',
    ],
    links: [{ text: 'Top', link: 'https://norito-blog.com' }],
    frontline: false,
    ignoreIndex: true,
    title: "記事一覧",
  },
  siteMetadata: {
    title: 'norito-blog | noritake',
    description: '技術メモ',
    ogImage: null,
    docsLocation: '',
    // docsLocation: 'https://github.com/hasura/gatsby-gitbook-boilerplate/tree/master/content',
    favicon: 'https://graphql-engine-cdn.hasura.io/img/hasura_icon_black.svg',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
